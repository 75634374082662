import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import hero from '../img/hero.svg';

import Layout from '../components/Layout';

export const IndexPageTemplate = ({ title, subheading, mainpitch }) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundColor: '#0046b8',
        backgroundImage: `url(${hero})`,
        backgroundPosition: `top left`,
        backgroundRepeat: `repeat`,
        backgroundSize: `300px 300px`,
        backgroundAttachment: `fixed`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            backgroundColor: 'rgb(70, 184, 0, 0.85)',
            textShadow: `1px 1px 2px rgb(0,0,0, 0.5)`,
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            backgroundColor: 'rgb(70, 184, 0, 0.85)',
            textShadow: `1px 1px 2px rgb(0,0,0, 0.5)`,
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title"> {mainpitch.title} </h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle"> {mainpitch.description} </h3>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest Newsletters
                  </h3>
                  <p>Coming Soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
        mainpitch {
          title
          description
        }
      }
    }
  }
`;
